import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, setCurrentPage, totalPages, fetchMessages }) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchMessages(undefined, page);
  };

  // Only render pagination if there are pages to show
  if (totalPages <= 1) return null;

  return (
    <Pagination>
      <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
      {[...Array(totalPages).keys()].map((_, idx) => (
        <Pagination.Item key={idx} active={idx + 1 === currentPage} onClick={() => handlePageChange(idx + 1)}>
          {idx + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    </Pagination>
  );
};

export default PaginationComponent;
