import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/logo-light-snapmails.png';

const Footer = () => {
  return (
    <div className="hk-footer position-static w-100 border-0 py-3 bg-light">
      <Container className="footer">
        <Row className="h-auto align-items-center justify-content-between">
          <Col xs="auto">
            <Link to="#" className="footer-brand">
              <img className="brand-img d-inline-block img-fluid" src={logo} alt="brand" width="150" />
            </Link>
          </Col>
          <Col xs="auto">
            <p className="footer-text mb-0">
              <span className="copy-text">Gazolin Production © 2022 All rights reserved.</span>
            </p>
          </Col>
          <Col xs="auto">
            <div className="footer-social-btn-wrap">
              <Link to="#" className="btn btn-icon btn-rounded btn-soft-light btn-sm mx-1">
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link to="#" className="btn btn-icon btn-rounded btn-soft-light btn-sm mx-1">
                <FontAwesomeIcon icon={faLinkedin} />
              </Link>
              <Link to="#" className="btn btn-icon btn-rounded btn-soft-light btn-sm mx-1">
                <FontAwesomeIcon icon={faGoogle} />
              </Link>
              <Link to="#" className="btn btn-icon btn-rounded btn-soft-light btn-sm mx-1">
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
