import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Container, Row, Col, Button, Form, Spinner, Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap';
import 'simplebar-react/dist/simplebar.min.css';
import './styles/App.css';

import Header from './components/Header';
import EmailList from './components/EmailList';
import PaginationComponent from './components/PaginationComponent';
import MessageDetail from './components/MessageDetail';
import ScrollContent from './components/ScrollContent';
import Footer from './components/Footer';

const API_MAILBOX_BASE_URL = '/api/v1/mailbox';

function App() {
  const [email, setEmail] = useState('');
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchMessages = useCallback(async (emailToFetch = email, page = 1, sortOrder = 'desc') => {
    if (emailToFetch) {
      try {
        setLoading(true);
        const response = await axios.get(`${API_MAILBOX_BASE_URL}/${emailToFetch}/messages`, {
          params: {
            skip: (page - 1) * 10,
            limit: 10,
            sort_order: sortOrder,
          },
        });

        console.log('Response data:', response.data);
        setMessages(response.data.map(message => ({
          ...message,
          status: message.status || 'Unread'
        })));
        setCurrentPage(page);
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [email]);

  const fetchTotalMessages = useCallback(async (emailToFetch = email) => {
    if (emailToFetch) {
      try {
        const response = await axios.get(`${API_MAILBOX_BASE_URL}/${emailToFetch}/count`);
        setTotalPages(Math.ceil(response.data.count / 10));
      } catch (error) {
        console.error('Error fetching total messages:', error);
      }
    }
  }, [email]);

  const createMailbox = useCallback(async () => {
    try {
      const response = await axios.post(`${API_MAILBOX_BASE_URL}/create`);
      const newEmail = response.data.email;
      setEmail(newEmail);
      Cookies.set('temp_email', newEmail, { expires: 1 / 24 });
      setMessages([]);
      setSelectedMessage(null);
      await fetchMessages(newEmail);
      await fetchTotalMessages(newEmail);
    } catch (error) {
      console.error('Error creating mailbox:', error);
    }
  }, [fetchMessages, fetchTotalMessages]);

  const updateMessageStatus = useCallback((messageId, newStatus) => {
    setMessages(prevMessages =>
      prevMessages.map(message =>
        message.id === messageId ? { ...message, status: newStatus } : message
      )
    );
  }, []);

  const fetchMessage = useCallback(async (messageId) => {
    if (email && messageId) {
      try {
        const response = await axios.get(`${API_MAILBOX_BASE_URL}/${email}/messages/${messageId}`);
        setSelectedMessage(response.data);
        updateMessageStatus(messageId, response.data.status);
      } catch (error) {
        console.error('Error fetching message:', error);
      }
    }
  }, [email, updateMessageStatus]);

  const deleteMailbox = useCallback(async () => {
    if (email) {
      try {
        await axios.delete(`${API_MAILBOX_BASE_URL}/${email}`);
        setEmail('');
        setMessages([]);
        setSelectedMessage(null);
        Cookies.remove('temp_email');
      } catch (error) {
        console.error('Error deleting mailbox:', error);
      }
    }
  }, [email]);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(email);
      setCopySuccess('Email copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (error) {
      console.error('Failed to copy email:', error);
    }
  }, [email]);

  const updateSingleMessageStatus = useCallback(async (messageId) => {
    try {
      const response = await axios.get(`${API_MAILBOX_BASE_URL}/${email}/messages/${messageId}`);
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === messageId ? { ...msg, status: response.data.status } : msg
        )
      );
    } catch (error) {
      console.error('Error updating single message status:', error);
    }
  }, [email]);

  useEffect(() => {
    const savedEmail = Cookies.get('temp_email');
    if (savedEmail) {
      setEmail(savedEmail);
      fetchMessages(savedEmail);
      fetchTotalMessages(savedEmail);
    } else {
      createMailbox();
    }
  }, [createMailbox, fetchMessages, fetchTotalMessages]);

  useEffect(() => {
    if (email && !selectedMessage) {
      const interval = setInterval(() => {
        fetchMessages(email, currentPage);
        fetchTotalMessages(email);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [email, currentPage, selectedMessage, fetchMessages, fetchTotalMessages]);

  const Paragraphs = () => {
    return (
      <>
        <p className="p-xl mb-3">
          paragraph XL Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.
        </p>
        <p className="p-lg mb-3">
          paragraph LG Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.
        </p>
        <p className="mb-3">
          paragraph Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.
        </p>
        <p className="p-sm mb-3">
          paragraph SM Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.
        </p>
        <p className="p-xs mb-3">
          paragraph XS Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est non commodo luctus.
        </p>
      </>
    );
  };

  const AccordionsSoft = () => {
    return (
      <Accordion className="accordion-soft" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Accordion Item #1</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis dignissimos sint voluptates, laborum perspiciatis laudantium quam, dolor, consequatur a est maxime optio quidem doloribus autem! Facilis maiores ratione aliquid, ex veniam nobis est accusantium harum sequi, accusamus libero optio sint.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam asperiores ea saepe velit perspiciatis impedit beatae! Eos, sint corrupti. A amet saepe tempora laboriosam, repudiandae ipsa, quos facere vitae reiciendis earum quibusdam impedit dolore adipisci quidem voluptate assumenda fuga vel temporibus eligendi. Similique, at doloribus?
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Accordion Item #3</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet quia iure dolor dicta officiis! Voluptatibus dicta velit, alias expedita cupiditate consequuntur eveniet inventore, corporis maiores est, optio natus corrupti quo quibusdam? Voluptates error libero rerum voluptate molestias ea doloribus quis ipsa eveniet necessitatibus? Ad molestias dolores harum mollitia itaque error!
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  return (
    <Router>
      <div className="app-container d-flex flex-column" style={{ minHeight: '100vh', backgroundColor: '#edf9fe' }}>
        <Container>
          <Header />
        </Container>

        <Container className="flex-grow-1 d-flex justify-content-center align-items-center" style={{ maxWidth: '720px', width: '100%' }}>
          <div className="shadow-lg p-3 mb-5 bg-white rounded" style={{ width: '100%' }}>
            <Row className="mb-3">
              <Col>
                <div className="mb-1">
                  <strong>Your Free Box:</strong>
                </div>
                <Form.Control type="text" value={email} readOnly disabled className="mb-2" />
                <div className="d-flex justify-content-between mb-3">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-copy">{copySuccess || 'Copy to clipboard'}</Tooltip>}
                  >
                    <Button variant="primary" onClick={copyToClipboard}>Copy Email</Button>
                  </OverlayTrigger>
                  <Button variant="secondary" onClick={() => fetchMessages()}>Refresh</Button>
                  <Button variant="info" onClick={createMailbox}>Change</Button>
                  <Button variant="danger" onClick={deleteMailbox}>Delete</Button>
                </div>
              </Col>
            </Row>

            <div className="my-4" style={{ minHeight: '200px' }}>
              {loading && messages.length === 0 ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : selectedMessage ? (
                <MessageDetail
                  message={selectedMessage}
                  setSelectedMessage={setSelectedMessage}
                  updateMessageStatus={updateMessageStatus}
                  updateSingleMessageStatus={updateSingleMessageStatus}
                />
              ) : (
                <>
                  {messages.length > 0 ? (
                    <>
                      <EmailList messages={messages} onMessageSelect={fetchMessage} />
                      <PaginationComponent
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        fetchMessages={fetchMessages}
                      />
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                      <span>No messages found</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Container>

        <Container>
          <ScrollContent />
        </Container>

        <Container className="my-5">
          <Paragraphs />
        </Container>

        <Container className="my-5">
          <AccordionsSoft />
        </Container>

        <Container>
          <Footer />
        </Container>
      </div>
    </Router>
  );
}

export default App;