import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const EmailList = ({ messages, onMessageSelect }) => {
  const [sortedMessages, setSortedMessages] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });

  useEffect(() => {
    const sortedMsg = [...messages].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setSortedMessages(sortedMsg);
  }, [messages, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getMessageStyle = (status) => {
    return status === 'Opened'
      ? { color: '#6c757d', fontWeight: 'normal' }
      : { color: '#212529', fontWeight: 'bold' };
  };

  const getMessageIcon = (status) => {
    return status === 'Opened' ? faEnvelopeOpen : faEnvelope;
  };

  return (
    <Table responsive striped hover className="mb-0">
      <thead>
        <tr>
          <th className="mnw-150p" onClick={() => requestSort('subject')}>
            Subject {sortConfig.key === 'subject' && (
              <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
            )}
          </th>
          <th className="mnw-200p" onClick={() => requestSort('from')}>
            From {sortConfig.key === 'from' && (
              <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
            )}
          </th>
          <th className="mnw-150p" onClick={() => requestSort('date')}>
            Date {sortConfig.key === 'date' && (
              <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
            )}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {sortedMessages.map((message) => (
          <tr
            key={message.id}
            onClick={() => onMessageSelect(message.id)}
            style={{ cursor: 'pointer', ...getMessageStyle(message.status) }}
          >
            <td className="text-truncate">{message.subject}</td>
            <td className="text-truncate">{message.from}</td>
            <td className="text-truncate">{new Date(message.date).toLocaleString()}</td>
            <td className="text-end">
              <FontAwesomeIcon icon={getMessageIcon(message.status)} />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default EmailList;
