import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/logo-light-snapmails.png';

const Header = () => {
  return (
    <div className="hk-pg-header py-3 bg-light">
      <Container className="px-3">
        <Row className="d-flex align-items-center justify-content-between">
          <Col xs="auto" className="d-flex align-items-center">
            <img src={logo} alt="brand" className="brand-img d-inline-block img-fluid" width="150" />
            <div className="ms-3">
              <h1 className="pg-title">SnapMail</h1>
              <div className="pg-subtitle">Get Free Box</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
