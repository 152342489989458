import React, { useEffect, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const MessageDetail = ({ message, setSelectedMessage, updateMessageStatus, updateSingleMessageStatus }) => {
  const statusUpdated = useRef(false);

  const updateStatusWithBackoff = useCallback(async (retries = 3, delay = 1000) => {
    if (message.status !== 'Opened' && !statusUpdated.current) {
      try {
        statusUpdated.current = true;
        await axios.put(`/api/v1/messages/${message.id}/status`, {
          status: 'Opened'
        });
        updateMessageStatus(message.id, 'Opened');
        updateSingleMessageStatus(message.id);
      } catch (error) {
        console.error('Ошибка при обновлении статуса сообщения:', error);
        statusUpdated.current = false;

        if (error.response && error.response.status === 429 && retries > 0) {
          const retryAfter = error.response.headers['retry-after'] || 1;
          const backoffTime = Math.min(parseInt(retryAfter) * 1000, delay);

          console.log(`Превышен лимит запросов. Повторная попытка через ${backoffTime}мс`);

          setTimeout(() => {
            updateStatusWithBackoff(retries - 1, delay * 2);
          }, backoffTime);
        }
      }
    }
  }, [message.id, message.status, updateMessageStatus, updateSingleMessageStatus]);

  useEffect(() => {
    updateStatusWithBackoff();
  }, [updateStatusWithBackoff, message.id, message.status]);

  return (
    <div>
      <Button variant="secondary" className="mb-3" onClick={() => setSelectedMessage(null)}>Вернуться к списку</Button>
      <h2>{message.subject}</h2>
      <h4>От: {message.from}</h4>
      <p>Дата: {new Date(message.date).toLocaleString()}</p>
      <div>
        <p className="mb-3">{message.text}</p>
      </div>
    </div>
  );
};

export default MessageDetail;